import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { DataContainsNode, useTransformNode } from '../../hooks/useTransformer';
import { MainTitle, TitleRead, TitleWrapper } from './CommonElement';
import Nl2Br from './Nl2Br';
import SharedCss from './SharedCss';

const OutcomesWrapper = styled.div`
  /** Common Css **/
  ${SharedCss}

  padding: 50px 30px;
  text-align: center;
  background: #efefef;
`;

const OutcomesTitleJa = styled(MainTitle)``;

const OutcomesTitleRead = styled(TitleRead)`
  body & {
    @media (min-width: 1024px) {
      font-size: 24px;
    }
  }
`;

const OutcomesPoint = styled.ul`
  body & {
    /* TAB */
    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
    }

    /* PC */
    @media (min-width: 1024px) {
      max-width: 1024px;
      margin: 0 auto;
    }
  }
`;

const OutcomesPointItem = styled.li`
  body & {
    margin-bottom: 60px;

    /* TAB */
    @media (min-width: 768px) {
      width: 48%;
    }
  }
`;

const OutcomesPointTitle = styled.h3`
  body & {
    margin-top: 10px;
    font-weight: 300;
    color: #0093bb;

    /* PC */
    @media (min-width: 1024px) {
      font-size: 30px;
    }
  }
`;

const OutcomesPointDetail = styled.p`
  body & {
    font-size: 13px;
    text-align: center;

    /* PC */
    @media (min-width: 1024px) {
      font-size: 16px;
    }
  }
`;

const OutcomesExperience = styled.div`
  body & {
    width: 100%;

    iframe {
      width: 100%;
      height: 200px;
    }

    /* PC */
    @media (min-width: 1024px) {
      max-width: 1024px;
      margin: 0 auto;

      iframe {
        height: 600px;
      }
    }
  }
`;

const WOS_DATA_QUERY = graphql`
  query OutcomeData {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "projects-outcome" } } }
    ) {
      edges {
        node {
          id
          fields {
            language
            country
          }
          frontmatter {
            templateKey
            outcomeData {
              outcomeText1
              outcomeText2
              outcomeItem1Text1
              outcomeItem1Text2
              outcomeItem2Text1
              outcomeItem2Text2
              embeddedUrl
              outcomeAlt1
              outcomeAlt2
            }
          }
        }
      }
    }
  }
`;

const Outcome: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.OutcomeDataQuery>(
    WOS_DATA_QUERY,
  ) as DataContainsNode;
  const { frontmatter } = useTransformNode(data);
  const outcomeData = frontmatter?.outcomeData;

  return (
    <OutcomesWrapper>
      <TitleWrapper>
        <OutcomesTitleJa>{outcomeData?.outcomeText1}</OutcomesTitleJa>
        <OutcomesTitleRead>
          <Nl2Br text={outcomeData?.outcomeText2} sp={true} />
        </OutcomesTitleRead>
      </TitleWrapper>
      <OutcomesPoint>
        <OutcomesPointItem>
          <StaticImage
            src="../../../static/img/projects/efficiency.jpg"
            alt={outcomeData?.outcomeAlt1 || ''}
          />
          <OutcomesPointTitle>
            {outcomeData?.outcomeItem1Text1}
          </OutcomesPointTitle>
          <OutcomesPointDetail>
            <Nl2Br text={outcomeData?.outcomeItem1Text2} />
          </OutcomesPointDetail>
        </OutcomesPointItem>
        <OutcomesPointItem>
          <StaticImage
            src="../../../static/img/projects/communicated.jpg"
            alt={outcomeData?.outcomeAlt2 || ''}
          />
          <OutcomesPointTitle>
            {outcomeData?.outcomeItem2Text1}
          </OutcomesPointTitle>
          <OutcomesPointDetail>
            <Nl2Br text={outcomeData?.outcomeItem2Text2} />
          </OutcomesPointDetail>
        </OutcomesPointItem>
      </OutcomesPoint>
      <OutcomesExperience>
        <iframe
          title="vimeo-player"
          src="https://player.vimeo.com/video/484275309"
          width="100%"
          height="auto"
          frameBorder="0"
          allowFullScreen
        />
      </OutcomesExperience>
    </OutcomesWrapper>
  );
};

export default Outcome;
